<template>
  <div id="app" class="bg-dark py-lg-5">
    <div class="col-md-8 col-lg-6 col-sm-12 bg-lgray mx-auto rounded p-3 main-box">
      <router-view />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  methods: {
    ...mapActions(['loadAll'])
  },
  mounted() {
    this.loadAll()
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  background: #343a40;
}

html,
body,
#app {
  height: 100vh;
}

.main-box {
  height: 100%;
  min-height: 35em;
}

.cursor-pointer {
  cursor: pointer;
}

.bg-lgray {
  background: #f1f1f1;
}

.brandname {
  background-image: linear-gradient(to right, red, yellow);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-pink {
  color: #f020be;
}

.bg-pink {
  background-color: #f020be !important;
}
</style>
