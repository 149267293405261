import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/Home.vue'
import Players from '../views/Players.vue'
import Game from '../views/Game.vue'
import AllCards from '../views/AllCards.vue'
import EditCard from '../views/EditCard.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/players',
    name: 'Players',
    component: Players
  },
  {
    path: '/game',
    name: 'Game',
    component: Game
  },
  {
    path: '/allcards',
    name: 'AllCards',
    component: AllCards
  },
  {
    path: '/editcard',
    name: 'EditCard',
    component: EditCard
  },
  {
    path: '/editcard/:id',
    name: 'EditCard',
    component: EditCard
  },
  {
    path: '/elevate',
    name: 'Elevate',
    component: () => import(/* webpackChunkName: "elevate" */ '../views/Elevate.vue')
  },
  {
    path: '*',
    redirect: '/'
  }
]

const router = new VueRouter({
  routes
})

export default router
