import Vue from 'vue'
import Vuex from 'vuex'

import rootPath from '../rootPath'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: '',
    numDevices: 0,
    players: [],
    unapprovedCards: null,
    cards: [],
    gameModes: [],
    tags: [],
    includeTags: [],
    excludeTags: [],
    currentCard: 0,
    cardsToPlay: [],
    colorMap: {
      dark: { bg: 'dark', text: 'white' },
      blue: { bg: 'primary', text: 'white' },
      gray: { bg: 'secondary', text: 'white' },
      green: { bg: 'success', text: 'white' },
      yellow: { bg: 'warning', text: 'white' },
      red: { bg: 'danger', text: 'white' },
      light: { bg: 'light', text: 'dark' },
      pink: { bg: 'pink', text: 'white' }
    }
  },
  getters: {
    allowedCards(state) {
      const allCards = JSON.parse(JSON.stringify(state.cards))
      const it = state.includeTags
      const et = state.excludeTags
      return allCards.filter(
        c =>
          it.some(t => c.tags.includes(t)) &&
          !et.some(t => c.tags.includes(t)) &&
          (c.min_players === null || state.players.length >= c.min_players) &&
          (c.max_players === null || state.players.length <= c.max_players) &&
          !c.is_reversion_card
      )
    },
    numAllowedCards(state, getters) {
      return getters.allowedCards.length
    }
  },
  mutations: {
    setPlayers(state, players) {
      Vue.set(state, 'players', players)
    },
    setCards(state, cards) {
      Vue.set(state, 'cards', cards)
    },
    setUnapprovedCards(state, cards) {
      Vue.set(state, 'unapprovedCards', cards)
    },
    setGameModes(state, gameModes) {
      Vue.set(state, 'gameModes', gameModes)
    },
    setTags(state, tags) {
      Vue.set(state, 'tags', tags)
    },
    setIETags(state, { includeTags, excludeTags }) {
      state.includeTags = includeTags
      state.excludeTags = excludeTags
    },
    setCardsToPlay(state, cardsToPlay) {
      Vue.set(state, 'cardsToPlay', cardsToPlay)
    },
    setCurrentCard(state, current) {
      state.currentCard = current
    },
    setToken(state, token) {
      state.token = token
    },
    setNumDevices(state, numDevices) {
      state.numDevices = numDevices
    }
  },
  actions: {
    setPlayers({ commit }, players) {
      commit('setPlayers', players)
      localStorage.setItem('players', JSON.stringify(players))
    },
    loadAll({ commit, dispatch }) {
      const token = localStorage.getItem('token')
      if (token !== null) {
        commit('setToken', token)
      }

      dispatch('loadPlayers')
      dispatch('loadData')
    },
    loadPlayers({ commit }) {
      const players = localStorage.getItem('players')
      if (players !== null) {
        commit('setPlayers', JSON.parse(players))
      }
    },
    async loadData({ commit, dispatch }) {
      const cards = localStorage.getItem('cards')
      if (cards !== null) {
        commit('setCards', JSON.parse(cards))
      }

      const gameModes = localStorage.getItem('gameModes')
      if (gameModes !== null) {
        commit('setGameModes', JSON.parse(gameModes))
      }

      const tags = localStorage.getItem('tags')
      if (tags !== null) {
        commit('setTags', JSON.parse(tags))
      }

      const selectedTags = localStorage.getItem('selectedTags')
      if (selectedTags !== null) {
        commit('setIETags', JSON.parse(selectedTags))
      }

      const numDevices = localStorage.getItem('numDevices')
      if (numDevices !== null) {
        commit('setNumDevices', numDevices)
      }

      dispatch('syncData')
    },
    async syncData({ commit, state }) {
      const response = await fetch(rootPath + 'api/get_all_info.php?t=' + state.token)
      const json = await response.json()

      if (json.ok) {
        localStorage.setItem('cards', JSON.stringify(json.cards))
        localStorage.setItem('gameModes', JSON.stringify(json.game_modes))
        localStorage.setItem('tags', JSON.stringify(json.tags))
        localStorage.setItem('token', json.token)
        localStorage.setItem('numDevices', json.num_devices)

        commit('setToken', json.token)
        commit('setCards', json.cards)
        commit('setGameModes', json.game_modes)
        commit('setTags', json.tags)
        commit('setNumDevices', json.num_devices)

        if (json.unapproved_cards !== undefined) {
          commit('setUnapprovedCards', json.unapproved_cards)
        }
      }
    },
    setModeTags({ commit }, { includeTags, excludeTags }) {
      console.log(includeTags, excludeTags)
      commit('setIETags', { includeTags, excludeTags })
      commit('setCurrentCard', 0)
      commit('setCardsToPlay', [])
      localStorage.setItem('selectedTags', JSON.stringify({ includeTags, excludeTags }))
    },
    async drawCard({ commit, state, getters }) {
      if (state.cardsToPlay.length > 0) {
        const cardIndex = state.cardsToPlay.findIndex(c => c.position <= state.currentCard)
        if (cardIndex >= 0) {
          const card = JSON.parse(JSON.stringify(state.cards.find(a => a.id === state.cardsToPlay[cardIndex].id)))

          card.card_color = state.cardsToPlay[cardIndex].color

          const newToPlay = [...state.cardsToPlay]
          newToPlay.splice(cardIndex, 1)
          commit('setCardsToPlay', newToPlay)
          commit('setCurrentCard', state.currentCard + 1)

          return card
        }
      }

      if (getters.allowedCards.length > 0) {
        const randomPos = Math.floor(Math.random() * getters.allowedCards.length)
        const card = JSON.parse(JSON.stringify(getters.allowedCards[randomPos]))

        const tags = card.tags.map(t => state.tags.find(tt => tt.id === t))
        const prioMax = Math.max(...tags.map(t => t.tag_priority))
        const maxTags = tags.filter(t => t.tag_priority === prioMax)
        if (maxTags.length > 0) card.card_color = maxTags[0].card_color
        else card.card_color = 'light'

        if (card.reversion_card !== null) {
          // need to add revision card to stack
          const pos =
            state.currentCard +
            Math.floor(Math.random() * (card.max_cards_between_reversion - card.min_cards_between_reversion)) +
            card.min_cards_between_reversion
          commit('setCardsToPlay', [...state.cardsToPlay, { id: card.reversion_card, position: pos, color: card.card_color }])
        }
        commit('setCurrentCard', state.currentCard + 1)
        return card
      } else {
        return undefined
      }
    }
  },
  modules: {}
})
