<template>
  <div class="home text-center">
    <h1 class="brandname mt-5">BINFOLO</h1>

    <div class="mt-2 mb-3 px-2 text-muted">
      <router-link class="text-muted simple-btn" to="/AllCards"><b-icon-stickies /> &times; {{ cards.length }}</router-link>
      <span class="ml-2"><b-icon-phone /> &times; {{ numDevices }}</span>
      <span v-if="unapprovedCards !== null" class="ml-2" :class="{ 'text-danger': unapprovedCards.length > 0 }"
        ><b-icon-exclamation-diamond /> &times; {{ unapprovedCards.length }}</span
      >
    </div>

    <div class="mt-3 list px-2">
      <div
        v-for="mode in gameModes"
        :key="mode.id"
        @click="selectedMode = mode.id"
        class="card my-2 p-2 cursor-pointer mode"
        :class="{ 'mode-active': mode.id === selectedMode, ['bg-' + colorMap[mode.color].bg]: true }"
      >
        <h4 :class="['text-' + colorMap[mode.color].text]">{{ mode.name }}</h4>
        <p :class="['text-' + colorMap[mode.color].text]">{{ mode.description }}</p>
      </div>
      <!--
      <div
        @click="selectedMode = null"
        class="card my-2 p-2 cursor-pointer mode"
        :class="{ 'mode-active': selectedMode === null, ['bg-' + colorMap['dark'].bg]: true }"
      >
        <h4 :class="['text-' + colorMap['dark'].text]">Manuell</h4>
        <p :class="['text-' + colorMap['dark'].text]">Alles selber einstellen</p>
      </div>
      -->
    </div>

    <div class="btn-start text-center">
      <button @click="start" class="btn btn-outline-dark btn-xxl">START</button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'Home',
  components: {},
  data() {
    return {
      selectedMode: -1
    }
  },
  computed: {
    ...mapState(['gameModes', 'colorMap', 'cards', 'numDevices', 'unapprovedCards'])
  },
  methods: {
    ...mapActions(['setModeTags']),
    start() {
      if (this.selectedMode === -1) return

      if (this.selectedMode === null) {
        this.$router.push('/manual')
      } else {
        const mode = this.gameModes.find(a => a.id === this.selectedMode)
        this.setModeTags({ includeTags: mode.include_tags, excludeTags: mode.exclude_tags })
        this.$router.push('/players')
      }
    }
  }
}
</script>

<style scoped>
.btn-xxl {
  font-size: 2em;
}

.mode {
  margin-left: 20px;
  margin-right: 20px;
}

.mode-active {
  margin-left: 6px;
  margin-right: 6px;
}

.btn-start {
  position: absolute;
  bottom: 3em;
  left: 0;
  right: 0;
}

.list {
  overflow: auto;
  position: absolute;
  top: 8em;
  bottom: 8em;
  left: 0;
  right: 0;
}

.simple-btn:hover {
  text-decoration: none;
}
</style>
