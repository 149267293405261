<template>
  <div class="home text-center" style="height:100%">
    <router-link class="float-left text-dark" style="font-size:1.5em;" to="/allcards"><b-icon-chevron-left></b-icon-chevron-left></router-link>
    <br />
    <h2 class="text-secondary mt-3">Bearbeitungsmodus</h2>
    <div class="box">
      <div class="text-left">
        <label>Tags</label>
        <div v-for="tag in tags" :key="tag.id" class="form-check">
          <input v-model="selectedTags[tag.id]" type="checkbox" class="form-check-input" :id="'tag-' + tag.id" />
          <label
            class="form-check-label badge"
            :class="['bg-' + colorMap[tag.card_color].bg, 'text-' + colorMap[tag.card_color].text]"
            :id="'tag-' + tag.id"
            >{{ tag.name }}</label
          >
        </div>

        <div class="form-group">
          <label>minimale # Spieler (-1 falls egal):</label>
          <input v-model="minPlayers" type="number" class="form-control" min="-1" max="100" step="1" />
        </div>
        <div class="form-group">
          <label>maximale # Spieler (-1 falls egal):</label>
          <input v-model="maxPlayers" type="number" class="form-control" min="-1" max="100" step="1" />
        </div>

        <hr />

        <div class="form-group">
          <label>Titel</label>
          <input v-model="title" type="text" class="form-control" placeholder="Titel" />
        </div>
        <div class="form-group">
          <label>Text</label>
          <textarea v-model="text" class="form-control" rows="5" placeholder="..."></textarea>
          <p class="text-muted" style="font-size: 0.7em">
            Hilfe:<br />
            <b>$player1</b>, <b>$player2</b> ...<br />
            <b>${2-5}</b><br />
            <b>${A-Z}</b><br />
            <b>$*abc*$</b>
          </p>
        </div>

        <hr />

        <label>Vorschau</label>
        <div class="card text-center shadow playingcard" :class="'bg-' + colorMap['light'].bg">
          <div class="card-header">
            <h5 :class="'text-' + colorMap['light'].text">{{ title }}</h5>
          </div>
          <div class="card-body text-left" :class="'text-' + colorMap['light'].text" v-html="textFormatted"></div>
        </div>

        <hr />

        <label>Auflösungskarte</label>
        <br />
        <button @click="hasReversion = !hasReversion" class="btn btn-outline-dark">
          <b-icon-plus-circle /> Auflösungskarte {{ hasReversion ? 'entfernen' : 'hinzufügen' }}
        </button>
        <div v-if="hasReversion" class="mt-3">
          <div class="form-group">
            <label># Karten bis zur Auflösung (Minimum):</label>
            <input v-model="minCardsBetween" type="number" class="form-control" min="1" max="100" step="1" />
          </div>
          <div class="form-group">
            <label># Karten bis zur Auflösung (Maximum):</label>
            <input v-model="maxCardsBetween" type="number" class="form-control" min="1" max="100" step="1" />
          </div>

          <div class="form-group">
            <label>Titel</label>
            <input v-model="reversionTitle" type="text" class="form-control" placeholder="Titel" />
          </div>
          <div class="form-group">
            <label>Text</label>
            <textarea v-model="reversionText" class="form-control" rows="5" placeholder="..."></textarea>
            <p class="text-muted" style="font-size: 0.7em">
              Hilfe:<br />
              <b>$player1</b>, <b>$player2</b> ...<br />
              <b>${2-5}</b><br />
              <b>${A-Z}</b><br />
              <b>$*abc*$</b>
            </p>
          </div>

          <label>Vorschau</label>
          <div class="card text-center shadow playingcard" :class="'bg-' + colorMap['light'].bg">
            <div class="card-header">
              <h5 :class="'text-' + colorMap['light'].text">{{ reversionTitle }}</h5>
            </div>
            <div class="card-body text-left" :class="'text-' + colorMap['light'].text" v-html="reversionTextFormatted"></div>
          </div>
        </div>
      </div>
      <div class="mt-4 text-center">
        <button @click="save" class="btn btn-outline-dark btn-xxl">Speichern</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import rootPath from '../rootPath'

export default {
  name: 'EditCard',
  components: {},
  data() {
    return {
      cardId: null,
      title: '',
      reversionId: null,
      text: '',
      minPlayers: -1,
      maxPlayers: -1,
      reversionTitle: '',
      reversionText: '',
      selectedTags: {},
      hasReversion: false,
      minCardsBetween: 10,
      maxCardsBetween: 20
    }
  },
  computed: {
    ...mapState(['cards', 'unapprovedCards', 'colorMap', 'token', 'tags']),
    unapprovedList() {
      const unapproved = this.unapprovedCards

      if (unapproved === null) return []
      else return unapproved
    },
    textFormatted() {
      const t = this.text

      return this.highlightExpr(t)
    },
    reversionTextFormatted() {
      const t = this.reversionText

      return this.highlightExpr(t)
    }
  },
  methods: {
    ...mapActions(['syncData']),
    highlightExpr(text) {
      text = text.replace(/\n/g, '<br>')

      // insert bold text
      text = text.replace(/\$\*([^*$]|\$)*\*\$/g, val => '<b>' + val + '</b>')

      // insert random value
      text = text.replace(/\$\{(\d{1,2})-(\d{1,2})\}/g, val => '<b class="text-primary">' + val + '</b>')

      // insert random letter
      text = text.replace(/\$\{([A-Z])-([A-Z])\}/g, val => '<b class="text-info">' + val + '</b>')

      // insert usernames
      var found = true
      var pos = 1
      while (found) {
        found = false
        text = text.replace(new RegExp('\\$player' + pos, 'g'), val => {
          found = true
          return '<b class="text-success">' + val + '</b>'
        })
        pos++
      }

      return text
    },
    loadEditCard() {
      if (this.$route.params.id !== undefined) {
        const card = this.cards.find(c => c.id === parseInt(this.$route.params.id))
        if (card !== undefined) {
          this.cardId = card.id
          this.title = card.title
          this.text = card.text
          this.minPlayers = card.min_players
          this.maxPlayers = card.max_players

          this.hasReversion = card.reversion_card !== null
          if (this.hasReversion) {
            const reversionCard = this.cards.find(c => c.id === card.reversion_card)

            if (reversionCard !== undefined) {
              this.reversionTitle = reversionCard.title
              this.reversionText = reversionCard.text
              this.reversionId = reversionCard.id
            }

            this.minCardsBetween = card.min_cards_between_reversion
            this.maxCardsBetween = card.max_cards_between_reversion
          }

          const tObj = {}
          card.tags.forEach(t => {
            tObj[t] = true
          })
          this.$set(this, 'selectedTags', tObj)
        }
      }
    },
    async save() {
      const request = await fetch(rootPath + 'api/edit_card.php?t=' + this.token, {
        method: 'POST',
        body: JSON.stringify({
          id: this.cardId,
          reversionId: this.reversionId,
          title: this.title,
          text: this.text,
          minPlayers: this.minPlayers,
          maxPlayers: this.maxPlayers,
          hasReversion: this.hasReversion,
          reversionTitle: this.reversionTitle,
          reversionText: this.reversionText,
          minCardsBetween: this.minCardsBetween,
          maxCardsBetween: this.maxCardsBetween,
          tags: Object.keys(this.selectedTags)
            .filter(t => this.selectedTags[t] === true)
            .map(t => parseInt(t))
        }),
        headers: {
          'Content-type': 'application/json; charset=UTF-8'
        }
      })

      const json = await request.json()

      if (json.ok) {
        await this.syncData()
        this.$router.push('/allcards')
      }
    }
  },
  mounted() {
    this.loadEditCard()
  },
  watch: {
    cards() {
      if (this.cardId === null) this.loadEditCard()
    }
  }
}
</script>

<style scoped>
.box {
  height: calc(100% - 5.5rem);
  overflow: auto;
}

.btn-xxl {
  font-size: 2em;
}

.btn-start {
  position: absolute;
  bottom: 3em;
  left: 0;
  right: 0;
}

/*
.playingcard > div {
  max-height: 8rem;
  overflow: auto;
}
*/

.card-container {
  height: calc(100% - 5.5rem);
  overflow: auto;
}

.btn-link:hover {
  text-decoration: none;
}
</style>
