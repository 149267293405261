<template>
  <div class="all text-center">
    <router-link class="float-left text-dark" style="font-size:1.5em;" to="/"><b-icon-chevron-left></b-icon-chevron-left></router-link>
    <br />
    <h2 @click="headingClicked" class="text-secondary mt-3">Alle Karten</h2>
    <div class="card-container">
      <button @click="$router.push('/editcard')" class="btn btn-link"><b-icon-plus-circle /> Karte hinzufügen</button>
      <div v-for="card in unapprovedList" :key="card.id" class="card my-3 shadow playingcard" :class="'bg-' + colorMap['light'].bg">
        <div class="card-header">
          <span class="text-danger float-left" style="font-size: 1.2em">
            <b-icon-exclamation-diamond />
          </span>
          <button @click="approve(card.id, card.reversion_card)" class="btn btn-link text-success float-right p-0 m-0"><b-icon-check-circle /></button>
          <button @click="decline(card.id, card.reversion_card)" class="btn btn-link text-danger float-right p-0 m-0 mr-2"><b-icon-x-circle /></button>
          <h5 :class="'text-' + colorMap['light'].text">{{ card.title }}</h5>
        </div>
        <div class="card-body text-left">
          <div :class="'text-' + colorMap['light'].text" v-html="highlightExpr(card.text)"></div>
          <span
            v-for="tag in card.tags"
            :key="tag.id"
            class="badge mr-2"
            :class="['bg-' + colorMap[tag.card_color].bg, 'text-' + colorMap[tag.card_color].text]"
            >{{ tag.name }}</span
          >
          <hr />
          <span class="text-muted">
            <b>Spieler:</b> [{{ card.min_players === null ? '-' : card.min_players }}, {{ card.max_players === null ? '-' : card.max_players }}]
          </span>

          <div v-if="card.reversion_card !== null" class="mt-3">
            <div class="card text-center shadow playingcard mb-3" :class="'bg-' + colorMap['light'].bg">
              <div class="card-header">
                <h5 :class="'text-' + colorMap['light'].text">{{ card.reversion_card.title }}</h5>
              </div>
              <div class="card-body text-left" :class="'text-' + colorMap['light'].text" v-html="highlightExpr(card.reversion_card.text)"></div>
            </div>
            <span class="text-muted"><b>Abstand Auflösung:</b> [{{ card.min_cards_between_reversion }}, {{ card.max_cards_between_reversion }}]</span>
          </div>
        </div>
      </div>

      <div v-for="card in processedCards" :key="card.id" class="card my-3 shadow playingcard" :class="'bg-' + colorMap['light'].bg">
        <div class="card-header">
          <button @click="$router.push('/editcard/' + card.id)" class="btn btn-link float-right p-0 m-0">
            <b-icon-pencil v-if="unapprovedCards !== null" />
            <b-icon-clipboard v-else />
          </button>
          <h5 :class="'text-' + colorMap['light'].text">{{ card.title }}</h5>
        </div>
        <div class="card-body text-left">
          <div :class="'text-' + colorMap['light'].text" v-html="highlightExpr(card.text)"></div>
          <span
            v-for="tag in card.tags"
            :key="tag.id"
            class="badge mr-2"
            :class="['bg-' + colorMap[tag.card_color].bg, 'text-' + colorMap[tag.card_color].text]"
            >{{ tag.name }}</span
          >
          <hr />
          <span class="text-muted">
            <b>Spieler:</b> [{{ card.min_players === null ? '-' : card.min_players }}, {{ card.max_players === null ? '-' : card.max_players }}]
          </span>

          <div v-if="card.reversion_card !== null" class="mt-3">
            <div class="card text-center shadow playingcard mb-3" :class="'bg-' + colorMap['light'].bg">
              <div class="card-header">
                <h5 :class="'text-' + colorMap['light'].text">{{ card.reversion_card.title }}</h5>
              </div>
              <div class="card-body text-left" :class="'text-' + colorMap['light'].text" v-html="highlightExpr(card.reversion_card.text)"></div>
            </div>

            <span class="text-muted"><b>Abstand Auflösung:</b> [{{ card.min_cards_between_reversion }}, {{ card.max_cards_between_reversion }}]</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import rootPath from '../rootPath'

export default {
  name: 'AllCards',
  components: {},
  data() {
    return {
      numClicks: 0,
      numDeclineClicks: {}
    }
  },
  computed: {
    ...mapState(['cards', 'unapprovedCards', 'colorMap', 'token', 'tags']),
    unapprovedList() {
      const unapproved = this.unapprovedCards

      if (unapproved === null) return []

      const cards = JSON.parse(JSON.stringify(unapproved))
      const tags = this.tags

      const normalCards = []

      cards.forEach(card => {
        card.tags = card.tags.map(t => tags.find(tt => tt.id === t))

        card.changed_time = new Date(card.changed_time)

        if (card.reversion_card !== null) {
          // find reversion card and add it to this card
          card.reversion_card = cards.find(c => c.id === card.reversion_card)
        }

        if (!card.is_reversion_card) {
          normalCards.push(card)
        }
      })

      return normalCards.sort((a, b) => b.changed_time.getTime() - a.changed_time.getTime())
    },
    processedCards() {
      const cards = JSON.parse(JSON.stringify(this.cards))
      const tags = this.tags

      const normalCards = []

      cards.forEach(card => {
        card.tags = card.tags.map(t => tags.find(tt => tt.id === t))

        card.changed_time = new Date(card.changed_time)

        if (card.reversion_card !== null) {
          // find reversion card and add it to this card
          card.reversion_card = cards.find(c => c.id === card.reversion_card)
        }

        if (!card.is_reversion_card) {
          normalCards.push(card)
        }
      })

      return normalCards.sort((a, b) => b.changed_time.getTime() - a.changed_time.getTime())
    }
  },
  methods: {
    ...mapActions(['syncData']),
    headingClicked() {
      console.log('clicked')
      this.numClicks += 1
      if (this.numClicks > 10) {
        this.$router.push('/elevate')
      }
    },
    approve(cardId, reversionCard) {
      fetch(rootPath + 'api/feedback_card.php?t=' + this.token + '&card=' + cardId + '&status=approve')
      if (reversionCard !== null) {
        fetch(rootPath + 'api/feedback_card.php?t=' + this.token + '&card=' + reversionCard.id + '&status=approve')
      }
      this.syncData()
    },
    decline(cardId, reversionCard) {
      if (this.numDeclineClicks[cardId] === undefined) {
        this.$set(this.numDeclineClicks, cardId, 1)
      } else this.numDeclineClicks[cardId] += 1

      if (this.numDeclineClicks[cardId] > 4) {
        fetch(rootPath + 'api/feedback_card.php?t=' + this.token + '&card=' + cardId + '&status=decline')
        if (reversionCard !== null) {
          fetch(rootPath + 'api/feedback_card.php?t=' + this.token + '&card=' + reversionCard.id + '&status=decline')
        }
        this.syncData()
      }
    },
    highlightExpr(text) {
      text = text.replace(/\n/g, '<br>')

      // insert bold text
      text = text.replace(/\$\*([^*$]|\$)*\*\$/g, val => '<b>' + val + '</b>')

      // insert random value
      text = text.replace(/\$\{(\d{1,2})-(\d{1,2})\}/g, val => '<b class="text-primary">' + val + '</b>')

      // insert random letter
      text = text.replace(/\$\{([A-Z])-([A-Z])\}/g, val => '<b class="text-info">' + val + '</b>')

      // insert usernames
      var found = true
      var pos = 1
      while (found) {
        found = false
        text = text.replace(new RegExp('\\$player' + pos, 'g'), val => {
          found = true
          return '<b class="text-success">' + val + '</b>'
        })
        pos++
      }

      return text
    }
  },
  watch: {}
}
</script>

<style scoped>
.btn-xxl {
  font-size: 2em;
}

.all {
  height: 100%;
}

.btn-start {
  position: absolute;
  bottom: 3em;
  left: 0;
  right: 0;
}

/*
.playingcard > div {
  max-height: 8rem;
  overflow: auto;
}
*/

.card-container {
  height: calc(100% - 5.5rem);
  overflow: auto;
}

.btn-link:hover {
  text-decoration: none;
}
</style>
