<template>
  <div class="home text-center">
    <router-link class="float-left text-dark" style="font-size:1.5em;" to="/"><b-icon-chevron-left></b-icon-chevron-left></router-link>
    <br />
    <h1 class="brandname mt-3">BINFOLO</h1>
    <h2 class="text-secondary mt-3">Spieler eintragen</h2>
    <div class="list bg-lgray px-2">
      <div v-for="(player, index) in playersList" :key="index">
        <b-input-group :prepend="index + 1 + ''" class="mt-3">
          <b-form-input v-model="playersList[index]"></b-form-input>
          <b-input-group-append>
            <b-button variant="outline-danger" tabindex="-1" @click="playersList.splice(index, 1)"><b-icon-trash /></b-button>
          </b-input-group-append>
        </b-input-group>
      </div>
    </div>

    <div class="btn-start text-center">
      <button @click="start" class="btn btn-outline-dark btn-xxl">Fertig</button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'Players',
  components: {},
  data() {
    return {
      playersList: ['']
    }
  },
  computed: {
    ...mapState(['players'])
  },
  methods: {
    ...mapActions(['setPlayers']),
    start() {
      const players = this.playersList.filter(a => a !== '')
      this.setPlayers(players)
      this.$router.push('/game')
    }
  },
  watch: {
    playersList(val) {
      if (val[val.length - 1] !== '') {
        val.push('')
      }
    }
  },
  mounted() {
    this.playersList = this.players
  }
}
</script>

<style scoped>
.btn-xxl {
  font-size: 2em;
}

.btn-start {
  position: absolute;
  bottom: 3em;
  left: 0;
  right: 0;
}

.list {
  overflow: auto;
  position: absolute;
  top: 10em;
  bottom: 8em;
  left: 0;
  right: 0;
}
</style>
