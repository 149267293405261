<template>
  <div class="home text-center">
    <router-link class="float-left text-dark" style="font-size:1.5em;" to="/"><b-icon-x-square /></router-link>
    <router-link class="float-left ml-3 text-dark" style="font-size:1.5em;" to="/players"><b-icon-people /></router-link>
    <br />
    <h1 class="brandname mt-3">BINFOLO</h1>

    <div class="cardholder px-3">
      <div v-if="currentCard === undefined && !loading">
        <i v-if="numAllowedCards > 0">Auf geht's!</i>
        <i v-else>Keine Karten zu den gewählten Einstellungen gefunden.</i>
      </div>
      <div v-else-if="loading">
        <b-icon icon="circle-fill" animation="throb" font-scale="4"></b-icon>
      </div>
      <div v-if="currentCard !== undefined" class="card shadow playingcard" :class="'bg-' + colorMap[currentCard.card_color].bg">
        <h5 class="card-header" :class="'text-' + colorMap[currentCard.card_color].text">{{ currentCard.title }}</h5>
        <div class="card-body text-left" :class="'text-' + colorMap[currentCard.card_color].text" v-html="processedText"></div>
      </div>
    </div>

    <div class="btn-start text-center">
      <button
        v-if="notStarted"
        @click="
          notStarted = false
          nextCard()
        "
        class="btn btn-outline-dark btn-xxl mt-5"
      >
        Los
      </button>
      <button v-else @click="nextCard" class="btn btn-outline-dark btn-xxl mt-5">Weiter</button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'Game',
  components: {},
  data() {
    return {
      currentCard: undefined,
      loading: false,
      notStarted: true
    }
  },
  computed: {
    ...mapState(['players', 'colorMap']),
    ...mapGetters(['numAllowedCards']),
    processedText() {
      const card = this.currentCard
      const players = [...this.players]
        .map(a => ({ v: a, r: Math.random() }))
        .sort((a, b) => a.r - b.r)
        .map(a => a.v)
      var text = card.text

      if (card === undefined) return ''

      // replace newline
      text = text.replace(/\n/g, '<br>')

      // insert bold text
      text = text.replace(/\$\*([^*$]|\$)*\*\$/g, val => '<b>' + val.substr(2, val.length - 4) + '</b>')

      // insert random value
      text = text.replace(/\$\{(\d{1,2})-(\d{1,2})\}/g, (val, a, b) => {
        var bx = parseInt(b)
        var ax = parseInt(a)
        if (isNaN(ax) || isNaN(bx) || bx < ax) return '${' + a + '-' + b + '}'

        const diff = bx - ax
        return Math.floor(Math.random() * diff) + ax
      })

      // insert random letter
      text = text.replace(/\$\{([A-Z])-([A-Z])\}/g, (val, a, b) => {
        var bx = b.charCodeAt(0)
        var ax = a.charCodeAt(0)
        if (ax < 65 || ax > 90 || bx < 65 || bx > 90) return '${' + a + '-' + b + '}'

        const diff = bx - ax
        var charCode = Math.floor(Math.random() * diff) + ax
        return String.fromCharCode(charCode)
      })

      // insert usernames
      var found = true
      var pos = 1
      while (found) {
        found = false
        text = text.replace(new RegExp('\\$player' + pos, 'g'), () => {
          found = true
          return players[pos - 1]
        })
        pos++
      }

      return text
    }
  },
  methods: {
    ...mapActions(['drawCard']),
    async nextCard() {
      if (this.loading) return // setTimeout(this.nextCard, 200)
      this.loading = true
      this.$set(this, 'currentCard', await this.drawCard())
      this.loading = false
    }
  },
  mounted() {
    // if (this.currentCard === undefined) this.nextCard()
  },
  watch: {
    numAllowedCards() {
      // if (this.currentCard === undefined) this.nextCard()
    }
  }
}
</script>

<style scoped>
.btn-xxl {
  font-size: 2em;
}

.btn-start {
  position: absolute;
  bottom: 3em;
  left: 0;
  right: 0;
}

.playingcard {
  font-size: 1.5em;
}

.cardholder {
  position: absolute;
  top: 8em;
  bottom: 8em;
  left: 0;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: center;
}

.cardholder > * {
  max-width: 100%;
  min-width: 100%;
  max-height: 100%;
}

.card-body {
  overflow: auto;
}
</style>
